import React, {Component}  from 'react';
import './App.css';
import jia from './img/jia.jpg';
import jian from './img/jian.jpg';
import cheng from './img/cheng.jpg';
import chu from './img/chu.jpg';
var domain="http://doctor-wxmp.lkimt.com/"

export default class Users extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
            , product: {
                url: ""
            }
        }
    }

    componentDidMount() {
    }
    componentWillMount(){


    }

    render() {
        return (
            <div className="container" >
             <div className={"radius-container"}>
                 <a href={domain+"s/jia"}><img src={jia}  alt={""}/> </a>
            </div>
                <div className={"radius-container"}>
                    <a href={domain+"s/jian"}>
                        <img src={jian}  alt={""}/>
                    </a>
                </div>

                <div className={"radius-container"}>
                    <a href={domain+"s/cheng"}>
                        <img src={cheng}  alt={""}/>
                    </a>
                </div>
                <div className={"radius-container"}>
                    <a href={domain+"s/chu"}>
                        <img src={chu}  alt={""}/>
                    </a>
                </div>
            </div>

        )

    }
}
// export default App;

